// 发送请求的封装
import axios from 'axios'
import { baseUrlAll } from './config'
// axios.defaults.withCredentials = true // 允许跨域携带cookie
// 创建一个axios实例，为其设置一些基础配置参数

const instance = axios.create({
  // 设置请求超时时间,如果超过5s就会报错
  timeout: 10000,
  withCredentials: true,
  // headers: {
  //   token: '69984502-5790-4594-8ea2-ff5a71bbefc8'
  // },
  // 表示我们请求接口的时候的根地址,
  // 此地址会拼接上请求中的url地址
  baseURL: baseUrlAll
})

export const instance1 = axios.create({
  // 设置请求超时时间,如果超过5s就会报错
  timeout: 10000,
  withCredentials: true,
  // 表示我们请求接口的时候的根地址,
  // 此地址会拼接上请求中的url地址

  baseURL: baseUrlAll
})

// --------------------------------------
// Add a request interceptor
//  全局请求拦截，所有的网络请求发起之前都会走这里
instance.interceptors.request.use(
  // config 表示配置信息(请求头 请求体)
  function(config) {
    // const token = document.cookie
    // config.headers.Authorization = token
    // Do something before request is sent
    // console.log('请求执行之前')
    // 当登录成功之后会把token信息存储在本地，此时可以同本地中取出token

    // authorization 是后台定的名字
    // Headers // 也是后台定的

    // config.headers.authorization = 'bearer ' + token
    // console.log(config)
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
//  全局响应拦截，所有的网络请求返回之后都会走这里
instance.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    // 打印错误信息 在 response 里有个status 是错误码
    if (error.response.status === 401) {
      // 跳转到登录页
      location.href = 'https://hrp.weilaihos.com/index/user/login.html'
    }
    // if (error.message.indexOf('timeout') > -1) {
    //   alert('请求超时，请重试')
    // }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)
// -----------------------------------------

export default instance

// 发起get请求 因为get请求是在url里传参 所以使用params
// url 请求地址
// params 传递的参数
export function get(url, params) {
  // instance是axios的实例
  return instance.get(url, {
    params
  })
}

// 可以简写成：
// export const get = (url, params) => instance.get(url, { params })

// 发起post请求
// url 请求地址
// data 传递的数据
export function post(url, data) {
  return instance1.post(url, data)
}
// 发起put请求
// url 请求地址
// data 传递的数据
export function put(url, data) {
  return instance.put(url, data)
}

// 发起delete请求
// url 请求地址
export function del(url) {
  return instance.delete(url)
}
